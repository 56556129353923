export const BASE_URL_API =
  process.env.REACT_APP_API_URL || "https://stage.tigest.club/api/v1";
  // "http://localhost:8080/api/v1";

export const BASE_URL_DASHBOARD = `${BASE_URL_API}/dashboard`;

export const settings = {
  colorPreset: "blue",
  contrast: "normal",
  direction: "ltr",
  layout: "vertical",
  navColor: "discreet",
  paletteMode: "light",
  responsiveFontSizes: true,
  stretch: false,
};

export const POST_TYPES = [
  { value: "post", label: "Post" },
  { value: "poll", label: "Poll" },
];

export const SOCIAL_NETWORKS = [
  {
    name: "linkedin",
    value: "Linkedin",
    displayName: "Linkedin",
    enabled: true,
    url: "linkedin.com",
  },
  {
    name: "telegram",
    value: "Telegram",
    displayName: "Telegram",
    enabled: true,
    url: "telegram.com",
  },
  {
    name: "twitter",
    value: "Twitter",
    displayName: "Twitter",
    enabled: true,
    url: "twitter.com",
  },
  {
    name: "bluesky",
    value: "Bluesky",
    displayName: "Bluesky",
    enabled: true,
    url: "https://bsky.app",
  },
  {
    name: "slack",
    value: "Slack",
    displayName: "Slack",
    enabled: true,
    url: "slack.com",
  },
  {
    name: "instagram",
    value: "Instagram",
    displayName: "Instagram",
    enabled: false,
    url: "instagram.com",
  },
  {
    name: "facebook",
    value: "Facebook",
    displayName: "Facebook",
    enabled: false,
    url: "facebook.com",
  },
  {
    name: "youtube",
    value: "YouTube",
    displayName: "YouTube",
    enabled: false,
    url: "youtube.com",
  },
  {
    name: "reddit",
    value: "Reddit",
    displayName: "Reddit",
    enabled: false,
    url: "reddit.com",
  },
  // {
  //   name: "ifttt/twitter",
  //   value: "IFTTT/Twitter",
  //   displayName: "Twitter/Webhook",
  //   enabled: true,
  //   url: "ifttt.com",
  // },
  // {
  //   name: "credential/twitter",
  //   value: "Credential/Twitter",
  //   displayName: "Twitter/Credentials",
  //   enabled: true,
  //   url: "twitter.com",
  // },
];
